/* eslint-disable */
import React, { useEffect } from 'react';
import {
  Route, Routes, useNavigate, useLocation,
} from 'react-router-dom';
import Types from '../types/Types';
import Meals from '../meals/Meals';
import goBack from '../../assets/images/arrow-left.svg';
import luqmalogo from '../../assets/images/luqmalogo.webp';
import './Home.scss';

function Home() {
  const location = useLocation();
  const navigate = useNavigate();
  const navigateBack = () => { navigate(-1); };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="home-page">
      <div className="wrapper">
        <img className="imglogo" src={luqmalogo} alt="logo" />  
      </div>
      <button className="goBack-wrapper" type="submit" onClick={navigateBack}>
        <img
          className={location.pathname === '/' ? 'hide' : 'go-Back'}
          src={goBack}
          alt="go-back"
        />
      </button>

      {/* 
        <div className="developer-information">
        <span className="location">
          &nbsp; עילבון
        </span>
        <span className="phone-number">
         &nbsp; 058-7970035
        </span>
        <span className="insta">
         &nbsp; luqma.2023
        </span>
      </div>
      */}


      <Routes>
        <Route exact path="/" element={<Types />} />
        <Route exact path="/meals" element={<Meals />} />
      </Routes>
    </div>
  );
}

export default Home;
