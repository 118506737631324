/* eslint-disable */
import c0 from '../assets/images/בוקר טוב/b.omlet.webp';
import c1 from '../assets/images/בוקר טוב/p.flalel.webp';
import c3 from '../assets/images/על האש/check steak.webp';
import c1t2 from '../assets/images/על האש/arosa.webp';
import c1t3 from '../assets/images/באגטים/b.checken2.webp';
import c1t4 from '../assets/images/באגטים/b.antricot.webp';
import c1t6 from '../assets/images/באגטים/b.checken.webp';
import c2t1 from '../assets/images/באגטים/b.kabab.webp';
import c2t2 from '../assets/images/באגטים/b.schnizel.webp';
import c3t1 from '../assets/images/באגטים/b.grill.webp';
import c3t2 from '../assets/images/בורגרים/burger.webp';
import c3t3 from '../assets/images/בורגרים/burger schn.webp';
import c3t5 from '../assets/images/בורגרים/burger check.webp';
import c4t2 from '../assets/images/טורטייה/t.check2.webp';
import c4t3 from '../assets/images/טורטייה/t.check.webp';
import c4t4 from '../assets/images/טורטייה/t.antricot.webp';
import c4t5 from '../assets/images/טורטייה/t.schn.webp';
import c4t6 from '../assets/images/טורטייה/t.kabab.webp';
import c5t1 from '../assets/images/מעושן/smoked.webp';
import c5t2 from '../assets/images/תוספות/chips.webp';
import c5t3 from '../assets/images/תוספות/potato.webp';
import c5t4 from '../assets/images/תוספות/onion.webp';
import c6t1 from '../assets/images/לחמניות/l.grillwebp.webp';
import c6t2 from '../assets/images/לחמניות/l.checken1.webp';
import c6t3 from '../assets/images/לחמניות/l.schn.webp';
import c7t1 from '../assets/images/שתייה/soda.webp';
import c7t2 from '../assets/images/שתייה/water.webp';
import c7t3 from '../assets/images/שתייה/zero.webp';
import c7t4 from '../assets/images/שתייה/cola.webp';
import c7t5 from '../assets/images/שתייה/sprite.webp';
import c7t6 from '../assets/images/שתייה/fanta.webp';
import c7t7 from '../assets/images/שתייה/grape.webp';
import c7t8 from '../assets/images/שתייה/tot.webp';
import c7t9 from '../assets/images/שתייה/s.zero.webp';
import c7t10 from '../assets/images/שתייה/coffee.jpeg';
import c7t11 from '../assets/images/שתייה/coffee2.jpeg';
import c7t12 from '../assets/images/שתייה/coffee3.jpeg';
import c7t13 from '../assets/images/שתייה/tee.jpeg';

const ar1 = [
  {
    id: 0,
    src: c1,
    name: 'פלאפל בפיתה',
    discription: '',
    price: 20,
    order: 0,
  },
  {
    id: 1,
    src: c0,
    name: 'בגט חביתה | חביתה בלחמניה',
    discription: '',
    price: '15 | ₪25',
    order: 0,
  },
];

const ar2 = [
  {
    id: 0,
    src: c1t2,
    name: 'ערוסה קבב',
    discription: '',
    price: 25,
    order: 0,
  },
  {
    id: 1,
    src: c3,
    name: 'חזה עוף בצלחת',
    discription: '',
    price: 50,
    order: 0,
  },
];

const ar3 = [
  {
    id: 0,
    src: c3t2,
    name: 'בורגר 200 גר',
    discription: '',
    price: 40,
    order: 0,
  },
  {
    id: 1,
    src: c3t5,
    name: 'בורגר עוף',
    discription: '',
    price: 35,
    order: 0,
  },
  {
    id: 2,
    src: c3t3,
    name: 'בורגר שניצל',
    discription: '',
    price: 35,
    order: 0,
  },

];
const ar4 = [
  {
    id: 0,
    src: c1t6,
    name: 'בגט עוף',
    discription: '',
    price: 35,
    order: 0,
  },
  {
    id: 1,
    src: c1t3,
    name: 'בגט צי׳קן',
    discription: 'מגיע עם גמבה, בצל, כרוב על הפלטה',
    price: 35,
    order: 0,
  },
  {
    id: 2,
    src: c1t4,
    name: 'בגט אנטריקוט',
    discription: 'מגיע עם גמבה ובצל / עגבניה ובצל',
    price: 45,
    order: 0,
  },
  {
    id: 3,
    src: c2t2,
    name: 'בגט שניצל',
    discription: '',
    price: 35,
    order: 0,
  },
  {
    id: 4,
    src: c2t1,
    name: 'בגט קבב',
    discription: '',
    price: 40,
    order: 0,
  },
  {
    id: 5,
    src: c3t1,
    name: 'בגט עוף על האש',
    discription: '',
    price: 35,
    order: 0,
  },

];
const ar5 = [
  {
    id: 0,
    src: c4t2,
    name: 'טורטייה עוף',
    discription: '',
    price: 33,
    order: 0,
  },
  {
    id: 1,
    src: c4t3,
    name: 'טורטייה צי׳קן',
    discription: 'מגיע עם גמבה, בצל, כרוב על הפלטה',
    price: 33,
    order: 0,
  },
  {
    id: 2,
    src: c4t4,
    name: 'טורטיה אנטריקות',
    discription: 'מגיע עם גמבה ובצל / עגבניה ובצל',
    price: 40,
    order: 0,
  },
  {
    id: 3,
    src: c4t5,
    name: 'טורטייה שניצל',
    discription: '',
    price: 33,
    order: 0,
  },
  {
    id: 4,
    src: c4t6,
    name: 'טורטייה קבב',
    discription: '',
    price: 38,
    order: 0,
  },

];
const ar6 = [
  {
    id: 0,
    src: c5t1,
    name: 'ג׳ביטה אסאדו מעושן',
    discription: 'מוגש עם צ׳יפס + שתייה לבחירה + צידר',
    price: 70,
    order: 0,
  },

];
const ar7 = [
  {
    id: 0,
    src: c6t1,
    name: 'לחמנייה עוף',
    discription: '',
    price: 25,
    order: 0,
  },
  {
    id: 1,
    src: c6t2,
    name: 'לחמנייה צי׳קן',
    discription: 'מגיע עם גמבה, בצל, כרוב על הפלטה',
    price: 25,
    order: 0,
  },
  {
    id: 2,
    src: c6t3,
    name: 'לחמנייה שניצל',
    discription: '',
    price: 25,
    order: 0,
  },

];
 const ar8 = [
  {
    id: 0,
    src: c5t2,
    name: 'צ׳יפס',
    discription: '',
    price: 'אישי  ₪10 | גדול 25',
    order: 0,
  },
  {
    id: 1,
    src: c5t3,
    name: 'פוטטו',
    discription: '',
    price: 'אישי  ₪15 | גדול 27',
    order: 0,
  },
  {
    id: 2,
    src: c5t4,
    name: 'טבעות בצל',
    discription: '',
    price: 'אישי  ₪15 | גדול 27',
    order: 0,
  },
];

const ar9 = [
  {
    id: 0,
    src: c7t2,
    name: 'מים',
    discription: '',
    price: '6',
    order: 0,
  },
  {
    id: 1,
    src: c7t1,
    name: 'סודה',
    discription: '',
    price: '6',
    order: 0,
  },
  {
    id: 2,
    src: c7t4,
    name: 'קולה',
    discription: '',
    price: '7',
    order: 0,
  },
  {
    id: 3,
    src: c7t3,
    name: 'קולה זירו',
    discription: '',
    price: '7',
    order: 0,
  },
  {
    id: 4,
    src: c7t5,
    name: 'ספרייט',
    discription: '',
    price: '7',
    order: 0,
  },
  {
    id: 5,
    src: c7t9,
    name: 'ספרייט זירו',
    discription: '',
    price: '7',
    order: 0,
  },
  {
    id: 6,
    src: c7t6,
    name: 'פאנטה',
    discription: '',
    price: '7',
    order: 0,
  },
  {
    id: 7,
    src: c7t7,
    name: 'ענבים',
    discription: '',
    price: '7',
    order: 0,
  },
  {
    id: 8,
    src: c7t8,
    name: 'תות בננה',
    discription: '',
    price: '7',
    order: 0,
  },

];

const ar10 = [
  {
    id: 0,
    src: c7t12,
    name: 'קפה רגיל',
    discription: '',
    price: 7,
    order: 0,
  },
  {
    id: 1,
    src: c7t11,
    name: 'קפה כפול',
    discription: '',
    price: 10,
    order: 0,
  },
  {
    id: 2,
    src: c7t10,
    name: 'הפוך',
    discription: '',
    price: 15,
    order: 0,
  },
  {
    id: 3,
    src: c7t13,
    name: 'תה',
    discription: '',
    price: 5,
    order: 0,
  },
];

const catagories = [
  {
    id: 0,
    name: 'בוקר טוב',
    src: c0,
    list: ar1,
  },
  {
    id: 1,
    name: 'על האש',
    src: c3,
    list: ar2,
  },
  {
    id: 2,
    name: 'בורגרים',
    src: c3t2,
    list: ar3,
  },
  {
    id: 3,
    name: 'בגטים',
    src: c2t2,
    list: ar4,
  },
  {
    id: 4,
    name: 'טורטייה',
    src: c4t3,
    list: ar5,
  },
  {
    id: 5,
    name: 'מעושנים',
    src: c5t1,
    list: ar6,
  },
  {
    id: 6,
    name: 'לחמנייה',
    src: c6t2,
    list: ar7,
  },
  {
    id: 7,
    name: 'תוספות',
    src: c5t2,
    list: ar8,
  },
  {
    id: 8,
    name: 'שתיה קרה',
    src: c7t4,
    list: ar9,
  },
  {
    id: 9,
    name: 'שתיה חמה',
    src: c7t11,
    list: ar10,
  },

];
export default catagories;
