/* eslint-disable */
import './Meal.scss';
import React from 'react';

function Meal(props) {
  const { meal } = props;
  const { src, name, discription, price } = meal;

  return (
    <div className="meal-container">
      <div className="image-container">
        <img src={src} alt={name} />
      </div>
      <div className="name-container">
        <span>{name}</span>
      </div>
      <div className="discription-container">
        <p>{discription}</p>
      </div>
      <div className="price-and-order-container">
        <span className="span1">{price}</span>
      </div>
    </div>
  );
}
export default Meal;
