/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

const initialState = localStorage.getItem('currentPage') || "/";

export const typesReducer = createSlice({
  name: 'types',
  initialState,
  reducers: {
    setType(state, action) {
      const currentPage = action.payload;
      localStorage.setItem('currentPage', currentPage);
      return currentPage;
    },
  },
});

export const { setType } = typesReducer.actions;
export default typesReducer.reducer;