/* eslint-disable */
import React,{useState, useEffect} from 'react';
import { v4 as uuidv4 } from 'uuid';
import './Meals.scss';
import { useSelector } from 'react-redux';
import Meal from '../Meal/Meal';

function Meals() {
  const indicesToShowText = [7];
  const [showaddonText, setShowaddonText] = useState(false);

  const indicesToShowText2 = [0];
  const [showbreakText, setShowbreakText] = useState(false);

  const id = useSelector((state) => state.types);
  const meals = useSelector((state) => state.meals[id].list);
  const CatagoryName = useSelector((state) => state.meals[id].name);
  const CatagoryId = useSelector((state) => state.meals[id].id);

  useEffect(() => {
    setShowaddonText(indicesToShowText.includes(CatagoryId));
  }, [CatagoryId]);

  useEffect(() => {
    setShowbreakText(indicesToShowText2.includes(CatagoryId));
  }, [CatagoryId]);

  return (
    <div className="meals-container">
      <h1 className='category-name'>{CatagoryName}</h1>
      {/*{showbreakText && indicesToShowText2.includes(CatagoryId) && (<h6 className="breakText">עד שעה 13:00</h6>)}*/}
      {meals.map((el) => <Meal meal={el} CatagoryId={CatagoryId} key={uuidv4()} />)}
      {showaddonText && indicesToShowText.includes(CatagoryId) && (
        <ul className="itemList">
          <li className="item">
            <h6 className="itemName">מיקס טבעות בצל + צ׳יפס </h6>
            <span className="itemPrice">אישי  ₪15 | גדול ₪25</span>
          </li>
          <li className="item">
            <h6 className="itemName">מיקס טבעות בצל + פוטטו </h6>
            <span className="itemPrice">אישי  ₪18 | גדול ₪28</span> 
          </li>
          <li className="item">
            <h6 className="itemName">מיקס טבעות בצל + פוטטו + צ׳יפס</h6>
            <span className="itemPrice">גדול ₪35</span>
          </li>

          <hr class="separator"></hr>

          <li className="item2">
            <div className="itemDetails">
              <span className="itemPrice">₪5</span>
              <h6 className="itemName">ביצה</h6>
            </div>
          </li>
          <li className="item2">
            <div className="itemDetails">
              <span className="itemPrice">₪12</span>
              <h6 className="itemName">ביקון טלה</h6>
            </div>
          </li>
          <li className="item2">
            <div className="itemDetails">
              <span className="itemPrice">₪7</span>
              <h6 className="itemName">גבינת צי׳דר</h6>
            </div>
          </li>
          <li className="item2">
            <div className="itemDetails">
              <span className="itemPrice">₪5</span>
              <h6 className="itemName">גבינה צהובה</h6>
            </div>
          </li>
          <li className="item2">
            <div className="itemDetails">
              <span className="itemPrice">₪7</span>
              <h6 className="itemName">פטריות</h6>
            </div>
          </li>
        </ul>
      )}
    </div>
  );
}

export default Meals;

